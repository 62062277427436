import {Button, Col, Row} from 'reactstrap';

type Props = {
  numberSelected: number,
  onExport: () => void
  onModalToggle: (isOpen: boolean) => void
  onFilterReset: () => void
}

const ExportControls = ({
                          numberSelected,
                          onExport,
                          onFilterReset,
                          onModalToggle
                        }: Props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-end align-items-center">
        <Button color="primary"
                className="mr-2"
                onClick={() => onModalToggle(true)}>
          Filter
        </Button>
        <Button color="danger"
                className="mr-2"
                onClick={() => onFilterReset()}>
          Reset Filter
        </Button>
        <Button color="success"
                disabled={numberSelected === 0}
                onClick={onExport}>
          Export
        </Button>
      </Col>
    </Row>
  );
};

export default ExportControls;