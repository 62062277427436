import {memo, useCallback, useMemo} from 'react';
import {Button, Card, CardBody, Col, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {
  FormikCheckboxGroup,
  FormikInput,
  FormikNumberInput,
  FormikRadioGroup,
  FormikSelect
} from '@reasoncorp/kyber-js';

import {Exemption, MajorReason} from '../../types';
import {AuditRequest, DenialRequest} from '../../types/request';
import {auditHistorySchema} from '../../schema';
import {AuditActivityCode} from '../../enums';
import AuditStatusPanel from './AuditStatusPanel';
import {formatDate} from '../../util';

type Props = {
  onSave: (auditRequest: AuditRequest, formikHelpers: FormikHelpers<AuditRequest>) => void
  exemption: Exemption
  editable: boolean
  majorReasons: MajorReason[]
}

const AuditTab = ({
                    onSave,
                    exemption,
                    editable,
                    majorReasons
                  }: Props) => {
  const initialValues: AuditRequest = useMemo(() => ({
    activityCode: '',
    callAt: formatDate(new Date(), 'yyyy-MM-dd'),
    denials: [
      {year: exemption.year, denied: '', percentAllowed: ''},
      {year: exemption.year - 1, denied: '', percentAllowed: ''},
      {year: exemption.year - 2, denied: '', percentAllowed: ''},
      {year: exemption.year - 3, denied: '', percentAllowed: ''}
    ],
    comments: '',
    callerName: '',
    majorReasonId: '',
    minorReasonId: '',
    denyValid: '3',
    bonaFide: false,
    managerReview: false,
    sendLetter: false,
    mailingAddressChanged: false,
    nonResident: exemption.status.nonResident
  }), [
    exemption
  ]);

  const handleSave = useCallback(async (values: AuditRequest,
                                        formikHelpers: FormikHelpers<AuditRequest>) => {
    await onSave(values, formikHelpers);
  }, [
    onSave
  ]);

  const renderMajorReasons = useMemo(() => (denyValid: string, activityCode: AuditActivityCode) => {
    if (denyValid === '1' || denyValid === '2') {
      const denyValidString = denyValid === '1' ? 'DENY' : 'VALID';
      return majorReasons.filter(majorReason => majorReason.denyOrValid === denyValidString)
        .filter(majorReason => activityCode === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED ?
          majorReason.description === 'FAILURE TO RESPOND' : majorReason.description !== 'FAILURE TO RESPOND')
        .map(majorReason => {
          return <option value={majorReason.id}
                         key={majorReason.id}>
            {majorReason.description}
          </option>;
        });
    } else {
      return null;
    }
  }, [
    majorReasons
  ]);

  const renderMinorReasons = useMemo(() => (majorReasonId: string) => {
    const majorReason = majorReasons.find(majorReason => majorReason.id === Number(majorReasonId));
    if (majorReason) {
      return majorReason.minorReasons.map(minorReason => {
        return <option value={minorReason.id}
                       key={minorReason.id}>
          {minorReason.description}
        </option>;
      });
    } else {
      return null;
    }
  }, [
    majorReasons
  ]);

  return (
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSave}
            validationSchema={auditHistorySchema}>
      {(formikProps) => (
        <Form autoComplete="off" onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col>
              <AuditStatusPanel status={exemption.status}/>
            </Col>
          </Row>
          <Card className="mb-2 audit-form">
            <CardBody>
              <Row>
                <Col sm={2}>
                  <FormikRadioGroup name="activityCode"
                                    onChange={(e) => {
                                      // Reset form, but with new activityCode
                                      formikProps.resetForm({
                                        values: {...initialValues, activityCode: e.target.value}
                                      });
                                    }}
                                    radioButtons={
                                      exemption.questionnaire?.letterOn && exemption.questionnaire?.queryCode && editable ?
                                        [
                                          {
                                            labelText: 'Mail Returned',
                                            value: AuditActivityCode.MAIL_RETURNED.toString()
                                          },
                                          {
                                            labelText: 'Taxpayer Call',
                                            value: AuditActivityCode.TAXPAYER_CALL.toString()
                                          },
                                          {
                                            labelText: 'Questionnaire Received',
                                            value: AuditActivityCode.QUESTIONNAIRE_RECEIVED.toString()
                                          },
                                          {labelText: 'Comments', value: AuditActivityCode.COMMENTS.toString()},
                                          {
                                            labelText: 'Undeliverable',
                                            value: AuditActivityCode.UNDELIVERABLE.toString()
                                          },
                                          {
                                            labelText: 'No Questionnaire Received',
                                            value: AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED.toString()
                                          },
                                          {labelText: 'Final Review', value: AuditActivityCode.FINAL_REVIEW.toString()}
                                        ] : [
                                          {labelText: 'Comments', value: AuditActivityCode.COMMENTS.toString()},
                                          {labelText: 'Taxpayer Call', value: AuditActivityCode.TAXPAYER_CALL.toString()}
                                        ]}/>
                </Col>
                {formikProps.values.activityCode &&
                  <Col sm={10}>
                    <Row>
                      <Col sm={4}>
                        <FormikInput name="callAt"
                                     type="date"
                                     disableFloatingLabel
                                     labelText="Date"
                                     required/>
                        {Number(formikProps.values.activityCode) === AuditActivityCode.TAXPAYER_CALL &&
                          <FormikInput name="callerName"
                                       aria-required={true}
                                       labelText="Caller Name"
                                       disableFloatingLabel/>
                        }
                        <FormikInput name="comments"
                                     labelText="Comments"
                                     aria-required={[
                                       AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED,
                                       AuditActivityCode.QUESTIONNAIRE_RECEIVED,
                                       AuditActivityCode.COMMENTS,
                                       AuditActivityCode.TAXPAYER_CALL
                                     ].includes(Number(formikProps.values.activityCode))}
                                     maxLength={350}
                                     rows={7}
                                     type="textarea"/>
                        <span className="text-secondary">Characters Remaining: {350 - (formikProps.values?.comments?.length ?? 0)}</span>
                      </Col>
                      <Col sm={4}>
                        {[AuditActivityCode.QUESTIONNAIRE_RECEIVED, AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED].includes(Number(formikProps.values.activityCode)) &&
                          <>
                            <FormikRadioGroup name="denyValid"
                                              inline
                                              aria-required={true}
                                              aria-label="Valid or Deny"
                                              labelText="Select"
                                              onChange={() => {
                                                formikProps.setFieldValue('majorReasonId', '');
                                                formikProps.setFieldValue('minorReasonId', '');
                                              }}
                                              radioButtons={[
                                                {labelText: 'Valid', value: '2'},
                                                {labelText: 'Deny', value: '1'}
                                              ]}/>
                            <FormikSelect name="majorReasonId"
                                          aria-required={true}
                                          disabled={!(formikProps.values.denyValid === '1' || formikProps.values.denyValid === '2')}
                                          labelText="Major Reason"
                                          onChange={(e) => {
                                            formikProps.setFieldValue('majorReasonId', e.target.value);
                                            formikProps.setFieldValue('minorReasonId', '');
                                          }}>
                              <option value="">Select</option>
                              {renderMajorReasons(formikProps.values.denyValid, Number(formikProps.values.activityCode))}
                            </FormikSelect>
                            <FormikSelect name="minorReasonId"
                                          aria-required={true}
                                          disabled={!formikProps.values.majorReasonId}
                                          labelText="Minor Reason">
                              <option value="">Select</option>
                              {renderMinorReasons(formikProps.values.majorReasonId)}
                            </FormikSelect>
                            {formikProps.values.denyValid === '1' && formikProps.values.majorReasonId && formikProps.values.minorReasonId &&
                              <>
                                <Row className="mb-2">
                                  <Col sm={2} className="text-center font-weight-bold text-nowrap">Years</Col>
                                  <Col sm={5}/>
                                  <Col sm={3} className="text-center font-weight-bold text-nowrap">PRE %</Col>
                                </Row>
                                {formikProps.values.denials.map((denial: DenialRequest, index: number) =>
                                  <Row key={`denial-${denial.year}`} className="border-top">
                                    <Col sm={2} className="text-center align-self-center text-nowrap">{denial.year}</Col>
                                    <Col sm={5} className="text-center align-self-center">
                                      <FormikRadioGroup name={`denials[${index}].denied`}
                                                        inline
                                                        aria-required={true}
                                                        formGroupClass="mb-0"
                                                        radioButtons={[
                                                          {labelText: 'Valid', value: 'false'},
                                                          {labelText: 'Deny', value: 'true'}
                                                        ]}
                                                        onChange={(e) => {
                                                          formikProps.setFieldValue(`denials[${index}].percentAllowed`, e.target.value === 'false' ? 100 : 0);
                                                        }}/>
                                    </Col>
                                    <Col sm={3}>
                                      {denial.denied !== 'false' &&
                                        <FormikNumberInput name={`denials[${index}].percentAllowed`}
                                                           maxLength={3}
                                                           disableFloatingLabel={true}
                                                           aria-required={true}
                                                           className="text-right align-self-center"
                                                           formGroupClass="text-nowrap"
                                                           aria-label="PRE %"/>
                                      }
                                    </Col>
                                  </Row>
                                )}
                              </>}
                          </>}
                      </Col>
                      <Col sm={4}>
                        {Number(formikProps.values.activityCode) === AuditActivityCode.COMMENTS &&
                          <FormikCheckboxGroup checkboxes={[
                            {name: 'mailingAddressChanged', labelText: 'Mailing Address Changed'},
                            {name: 'sendLetter', labelText: 'Send Letter'},
                            {name: 'managerReview', labelText: 'Manager Review'}
                          ]}/>
                        }
                        {Number(formikProps.values.activityCode) === AuditActivityCode.FINAL_REVIEW &&
                          <FormikCheckboxGroup checkboxes={[
                            {name: 'mailingAddressChanged', labelText: 'Mailing Address Changed'},
                            {name: 'nonResident', labelText: 'OST'},
                            {name: 'bonaFide', labelText: 'Bona Fide'}
                          ]}/>
                        }
                        {Number(formikProps.values.activityCode) === AuditActivityCode.QUESTIONNAIRE_RECEIVED &&
                          <FormikCheckboxGroup checkboxes={[
                            {name: 'mailingAddressChanged', labelText: 'Mailing Address Changed'},
                            {name: 'nonResident', labelText: 'OST'},
                            {name: 'bonaFide', labelText: 'Bona Fide'}
                          ]}/>
                        }
                        {Number(formikProps.values.activityCode) === AuditActivityCode.NO_QUESTIONNAIRE_RECEIVED &&
                          <FormikCheckboxGroup checkboxes={[
                            {name: 'mailingAddressChanged', labelText: 'Mailing Address Changed'},
                            {name: 'nonResident', labelText: 'OST'},
                            {name: 'bonaFide', labelText: 'Bona Fide'}
                          ]}/>
                        }
                      </Col>
                    </Row>
                  </Col>
                }
              </Row>
              <Row className="print-hide mt-3">
                <Col className="d-flex justify-content-end">
                  <Button color="success"
                          type="submit"
                          disabled={formikProps.isSubmitting || !formikProps.isValid}
                          onClick={formikProps.submitForm}>
                    Update
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>)}
    </Formik>
  );
};

export default memo(AuditTab);
