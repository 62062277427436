import {useCallback, useMemo} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {FormikInput, useAlerts} from '@reasoncorp/kyber-js';

import {nonResponseReportModalSchema} from '../../schema';
import {reportApi} from '../../api';
import {formatDate} from '../../util';
import * as messages from '../../messages';

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const NonResponseReportModal = ({
                                  isOpen,
                                  setIsOpen
                                }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const today = useMemo(() => new Date(), []);

  const initialValues = useMemo(() => ({
    letterSentOn: today
  }), [
    today
  ]);

  const handleSubmit = useCallback(async (letterSentOn: Date) => {
    const letterSentOnParam = formatDate(letterSentOn, 'yyyy-MM-dd');
    if (letterSentOnParam) {
      try {
        await reportApi.downloadNonResponseReport(letterSentOnParam);
        showSuccessAlert(messages.REPORT_DOWNLOAD_SUCCESSFUL);
      } catch (e) {
        showErrorAlert(messages.REPORT_DOWNLOAD_FAILED);
      }
      setIsOpen(false);
    }
  }, [
    setIsOpen,
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleCancel = useCallback((formikProps: FormikProps<any>) => {
    formikProps.resetForm();
    setIsOpen(false);
  }, [
    setIsOpen
  ]);

  return <Formik initialValues={initialValues}
                 validateOnMount
                 enableReinitialize
                 validationSchema={nonResponseReportModalSchema}
                 onSubmit={(values) => handleSubmit(values.letterSentOn)}>
    {(formikProps) => (
      <Modal isOpen={isOpen}
             backdrop="static"
             toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          Non Response Report
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormikInput name="letterSentOn"
                           type="date"
                           max={today}
                           formGroupClass="mb-0"
                           disableFloatingLabel
                           labelText="Letter Sent Date"/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button onClick={formikProps.submitForm}
                  className="mr-1"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                  color="success">
            {formikProps.isSubmitting && <>
              <FontAwesomeIcon icon="spinner"
                               spin
                               className="mr-1"/>
              Generating
            </>}
            {!formikProps.isSubmitting && 'Generate'}
          </Button>
          <Button onClick={() => handleCancel(formikProps)}
                  disabled={formikProps.isSubmitting}
                  color="secondary">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )}
  </Formik>;
};

export default NonResponseReportModal;